@import url(https://fonts.googleapis.com/css?family=Reenie+Beanie&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html, body, #root{
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  margin: 0;
  padding-left: 20pt;
  padding-right: 20pt;
  height: 100%;
  font-family: 'Reenie Beanie', cursive;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img{
  max-width: 80%;
}

.content{
  flex: 1 1;
  text-align: center;
}

.button {
  background-color: rgb(48, 143, 61);
  text-align: center;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 200px;


  outline-style:none;
  box-shadow:none;
  border-color:transparent;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button:active{
  background-color: rgb(26, 100, 36);
}

.button p {
  font-size: 30px;
  line-height: 50px;
  margin:0;
  padding:0;
  color: white;
  cursor: pointer;
}

.background{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 100%;
}
